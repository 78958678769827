.wyLQ8 {
  color: inherit;
}
._3zdL9 {
  font-family: EuclidCircularB;
}
._3XwM8 {
  font-family: NoticiaText;
}
._2rxSU {
  font-size: 32px;
  font-weight: normal;
  line-height: 48px;
}
._29x2I {
  font-size: 24px;
  font-weight: normal;
  line-height: 36px;
}
._3nXn9 {
  font-size: 20px;
  font-weight: normal;
  line-height: 30px;
}
._3Z7wY {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}
.zrkFI {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}
.JjWhN {
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
}
