.O9K1m {
  max-width: 1220px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 1079px) {
  .O9K1m {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 971px) {
  .O9K1m {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (max-width: 567px) {
  .O9K1m {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (max-width: 1439px) {
  .O9K1m {
    max-width: 920px;
  }
}
@media (max-width: 971px) {
  .O9K1m {
    max-width: 100%;
  }
}
