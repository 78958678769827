._1auJj {
  color: inherit;
}
._3pXnh {
  font-family: EuclidCircularB;
}
._10Uvx {
  font-size: 48px;
  font-weight: 600;
  line-height: 64px;
}
._VMU3 {
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
}
.xa25F {
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
}
._1jFid {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
._3gmY1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
._3tpJW {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
