.cps_d {
  font-weight: 600;
}
._2xIL0 {
  font-size: 48px;
  line-height: 1.25;
}
@media (max-width: 567px) {
  ._2xIL0 {
    font-size: 31px;
    line-height: 1.29;
  }
}
.qEsnf {
  font-size: 31px;
  line-height: 1.36;
}
._1y3QU {
  font-size: 18px;
  line-height: 1.33;
}
._1iR_Y {
  font-size: 15px;
  line-height: 1.33;
}
