._1-zcw {
  color: inherit;
}
._1LORH {
  font-family: EuclidCircularB;
}
.aPs1r {
  font-family: EuclidCircularBMedium;
}
.Jevtn {
  font-size: 24px;
  font-weight: normal;
  line-height: 28px;
}
._32NFn {
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
}
._22qg3 {
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
}
._39tuO {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}
._3yQKK {
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
}
._3ooN1 {
  font-size: 24px;
  font-weight: normal;
  line-height: 28px;
}
._2k1F4 {
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
}
.Lhxvf {
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
}
.kjcXa {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}
._1K2_q {
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
}
