._2d8WH h0 {
  margin-bottom: 24px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.111;
}
@media (max-width: 567px) {
  ._2d8WH h0 {
    margin-bottom: 6px;
  }
}
._2d8WH h1 {
  margin-bottom: 24px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.111;
}
@media (max-width: 567px) {
  ._2d8WH h1 {
    margin-bottom: 6px;
  }
}
._2d8WH h2 {
  margin-bottom: 24px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.111;
}
@media (max-width: 567px) {
  ._2d8WH h2 {
    margin-bottom: 6px;
  }
}
._2d8WH h3 {
  margin-bottom: 24px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.111;
}
@media (max-width: 567px) {
  ._2d8WH h3 {
    margin-bottom: 6px;
  }
}
._2d8WH h4 {
  margin-bottom: 24px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.111;
}
@media (max-width: 567px) {
  ._2d8WH h4 {
    margin-bottom: 6px;
  }
}
._2d8WH h5 {
  margin-bottom: 24px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.111;
}
@media (max-width: 567px) {
  ._2d8WH h5 {
    margin-bottom: 6px;
  }
}
._2d8WH h6 {
  margin-bottom: 1px;
  color: #101010;
}
._2d8WH a {
  line-height: 24px;
}
._2d8WH p {
  margin-bottom: 24px;
}
._2d8WH p:last-child {
  margin-bottom: 0;
}
._2d8WH ul,
._2d8WH ol {
  margin: 0 0 19px 20px;
}
._2d8WH ul > li,
._2d8WH ol > li {
  padding: 0 0 17px 10px;
}
._2d8WH ul > li:last-child,
._2d8WH ol > li:last-child {
  padding-bottom: 0;
}
._2d8WH ul {
  list-style: disc;
}
._2d8WH ol {
  list-style: decimal;
}
._2d8WH div {
  max-width: 100%;
}
._2d8WH > div {
  margin-bottom: 20px;
}
._2d8WH video {
  max-width: 100% !important;
  height: auto !important;
}
._2d8WH table {
  width: 100%;
  table-layout: fixed;
}
._2d8WH table th,
._2d8WH table td {
  width: 100%;
  padding: 10px;
  border: 1px solid #a1a490;
}
@media (max-width: 567px) {
  ._2d8WH table th,
  ._2d8WH table td {
    padding: 5px;
    font-size: 12px;
    word-break: break-word;
  }
}
[class*='Article__widget_type_quote'] ._2d8WH div {
  width: auto !important;
}
[class*='Article__widget_type_quote'] ._2d8WH video {
  width: 100%;
  height: auto;
}
