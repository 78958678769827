.aRUft {
  position: relative;
  display: block;
  max-width: 100%;
  overflow: hidden;
  background-color: inherit;
}
._1_TOz {
  width: 100%;
  vertical-align: middle;
}
._2n5_A {
  max-height: 100%;
}
